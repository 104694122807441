.more-details-card {
  width: 165px;
  height: 165px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 16px;
}
.more-details-card:hover {
  transform: scale(1.1);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.cc-highlight{
  -webkit-animation: bounce-anim 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate-reverse both;
        animation: bounce-anim 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate-reverse both;
          background-color: aquamarine;
}

@-webkit-keyframes bounce-anim {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
}
@keyframes bounce-anim {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
}
