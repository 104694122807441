.onboarded-patients-table .ant-pro-table-list-toolbar-container {
    display: block !important;
  }

  .onboarded-patients-table .ant-pagination-total-text {
    margin-right: auto;
  }
  
  .onboarded-patients-table .ant-pro-table-list-toolbar-right {
    justify-content: flex-start;
    flex-direction: row-reverse;
    align-items: flex-start !important;
  }
  
  .uploaded-patients-table .ant-pro-table-list-toolbar-container {
    display: block !important;
  }
  
  .uploaded-patients-table .ant-pagination-total-text {
    margin-right: auto;
  }
  
  .uploaded-patients-table .ant-pro-table-list-toolbar-right {
    justify-content: flex-start;
    flex-direction: row-reverse;
    align-items: flex-start !important;
  }
  
  .ant-pro-table-alert {
    display: flex;
    background-color: #fff;
    margin-block-end: 0;
  }
 
