
  .form{
    margin-left:6px;
    /* min-width: 540px;
    max-width: 540px; */
    min-width: 90%;
    min-height: 260px;
    max-height: 1260px;
    box-shadow: 1px 2px 4px 2px #858383;
    margin-bottom: 20px;
    border-radius: 3px;
    overflow-y:inherit;
  }

  .form-container{
    padding: 0 20px;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 20px;
    margin-left: 6px;
    
  }
  .form_header{
    margin-right:0px;
    margin-bottom: -10px;
  }
  .form_heading{
        font-size: 14px;
        margin-top:28px;
        margin-bottom: 10px;
  }
  .subHeading{
    font-size: 14px;
    margin-top:12px;
    margin-bottom: 12px;
}
  .body{
    width: 100%;
  }
  .back_button{
        padding: 4px 40px;
        border-radius: 30px;
        font-weight: 600;
        color: #2E5276;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #2E5276;
        text-transform: capitalize;
        margin-right: -140px;
        cursor: pointer;
        margin-left: 30px;
  
  }
  .next_button{
    padding: 4px 40px;
    margin-left: 330px;
    display: flex;
    align-items: center;
    background-color: #2E5276;
    text-transform: capitalize;
    color: #fff;
    border: none;
    border-radius: 30px;
    font-weight: 600;
    cursor: pointer;
  }
  .back_button svg{
    font-size: 20px;
    margin-left: -24px;
    margin-right: 20px;
  }
  .next_button svg{
    font-size: 20px;
    margin-left: 18px;
  
  }

/* jxzbashjzxbaSJXBZJAS */



.bottom_left{
  margin-left: 2rem ;
  height: 19rem;
  /* padding: 1rem; */
  border-radius: 0.5rem;
  width: 35%;
  position: relative;
}



.active_bg{
  background-color: rgb(237, 237, 237);
}

.Check{
  height: 3rem;
  border-radius: 3%;
  /* margin-right: 10px; */
}

.Ele{
  margin-left: 6px;
  padding: 1rem;
  font-size: 16px;
  color: #000000;
  font-weight: 500;
}

.Pro{
  height: 14px;
  width: 28%;
  background-color:  rgb(200, 196, 196);
  position: absolute;
  top: 18px;
  right: 50px;
  border-radius: 200px;
  margin-left: 20px;

}

.progressbar {
  height: 14px;
  background-color: rgb(200, 196, 196);
  /* margin-bottom: 50px; */
  border-radius: 0.7rem;
}

.progressbar div {
  height: 100%;
  border-radius: 0.7rem;
  background-color: #0fd76d;
}

.Pro1{
height: 14px;
width: 28%;
background-color:  rgb(200, 196, 196);
position: absolute;
top: 4.2rem;
right: 50px;
border-radius: 0.7rem;
}



.Pro2{
height: 14px;
width: 28%;
background-color:  rgb(200, 196, 196);
position: absolute;
top: 7.2rem;
right: 50px;
border-radius: 0.7rem;
}

.Pro3{
height: 14px;
width: 28%;
background-color:  rgb(200, 196, 196);
position: absolute;
top: 10rem;
right: 50px;
border-radius: 0.7rem;
}

.Pro4{
height: 14px;
width: 28%;
background-color:  rgb(200, 196, 196);
position: absolute;
top: 13.2rem;
right: 50px;
border-radius: 0.7rem;
}

.Pro5{
height: 14px;
width: 28%;
background-color:  rgb(200, 196, 196);
position: absolute;
top: 16.4rem;
right: 50px;
border-radius: 0.7rem;
}



.bye{
background-color: skyblue;
height: 2rem;
font-size: 1rem;
padding: 1rem;
border-radius: 0.5rem;
-webkit-border-radius: 0.5rem;
-moz-border-radius: 0.5rem;
-ms-border-radius: 0.5rem;
-o-border-radius: 0.5rem;
}

.bottom_scroll{
  margin-top: 1rem;
  margin-left: 1rem;
  height: 60vh;
  width: 100%;
  padding: 1rem;
  background: #fafafa;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
  margin-bottom: 40px;
}
.pdf-button{
  background-color: #2E5276;
  border: none;
  color: #fff;
}

@media print {
  .noprint {
     visibility: hidden;
  }
  .no-hadow{
    box-shadow: none !important;
  }
}
@page {
  size: A4;
  margin: 0; 
}

.list-print-div .MuiButton-root{
  display: none;
}

  @media screen and (max-width:700px) {
  .bottom_section{
    margin-top: 70px;
  }
    .form{
        margin-left: 5px;
        min-width: 380px;
        max-width: 380px;
    }
    .form-container{
        padding: 10px 15px;
    }
    .body{
        width: 100%;
    }
    .next_button{
        margin-left: 180px;
        padding: 6px 28px;
    }
  
    .form_heading{
      
        margin-top:40px;
  }
  .form_header{
  
    margin-bottom: -15px;
  }
  }
  @media screen and (max-width:1024px) {
    .bottom_section{
        display: flex;
        flex-direction: column;
        width: 100vw;
    }
    
  .bottom_left{
    width: 100vw;
    margin-left: -2px;
  }
  }
  