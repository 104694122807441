@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Work Sans", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-btn-primary {
  background-color: #d7a66d !important;
}
.ant-badge .ant-badge-dot {
  height: 10px;
  width: 10px;
  background-color: #d7a66d !important;
}
.ant-slider .ant-slider-track {
  height: 8px;
  background-color: rgba(215, 166, 109, 0.75);
}

.ant-slider-horizontal .ant-slider-dot {
  inset-block-start: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

textarea {
  border: 1px solid lightgray !important;
  border-radius: 6px;
}

.btn-info{
  background-color: #d7a66d !important;
  border: none !important;
  color: #fff !important;
}

/* To Fix left side extra margin in Pro Table Setting Toolbar */
.ant-pro-table-list-toolbar-setting-item {
  margin-inline: 0 !important;
}