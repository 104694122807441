.ant-tabs-left .ant-tabs-nav {
  min-width: 200px;
}

.ant-tabs-left .ant-tabs-ink-bar {
  display: none;
}

.ant-slider .ant-slider-rail {
  height: 8px;
}

.ant-slider .ant-slider-handle {
  inset-block-start: 3px;
}

.info-box {
  width: 12px;
  height: 12px;
  border-radius: 3px;
}

.react-autosuggest__input {
  width: 100%;
  height: 40px;
  padding: 10px 20px;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.react-autosuggest__input:focus {
  outline:none;
}

.active-patients-table .ant-pro-table-list-toolbar-container {
  display: block !important;
}

.active-patients-table .ant-pagination-total-text {
  margin-right: auto;
}

.active-patients-table .ant-pro-table-list-toolbar-right {
  justify-content: flex-start;
  flex-direction: row-reverse;
  align-items: flex-start !important;
}