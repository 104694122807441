.showEllipsis {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Vendor prefixes for webkit browsers */

  /* Standard properties for modern browsers */
  display: box;
  line-clamp: 4;
  box-orient: vertical;
}

.showFullText {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* Limit to 4 lines and show an ellipsis */
  -webkit-line-clamp: initial;
}

/* Add & Edit Internal Note Styles Start */
.add-edit-note-form input,
.add-edit-note-form textarea {
  border: none !important;
}

.add-edit-note-form textarea {
  height: 140px;
  resize: none;
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-inline-end: 8px;
}
.upload-list-inline {
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
}
/* Add & Edit Internal Note Styles End */
