.ccm_patients{
    width: 81vw;
    height:172px;
    margin-left:5px;
    margin-bottom: 40px;
}
.details_section{
    display: flex;
    gap: 250px
}
@media screen and (max-width:700px) {

    .details_section{
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .details_bottom{
        margin-left: 16px;
    }
}
@media screen and (max-width:1024px) {
    .header_section{
        width: 75vw;
        height: 170px;
        margin-left: 10px;
    }
    .details_section{
        display: flex;
        gap: 20px
    }

    .details_bottom{
        margin-left: 160px;
    }
}