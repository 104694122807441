:root {
  --animation-duration: 2.5s;
  --animation-delay-delta: 0.25s;
  --background-color: #d7a66d;
  --bar-height: 3px;
  --bar-width: 50px;
  --loader-height: 200px;
  --loader-width: 80px;
  --node-color-a: #d7a66d;
  --node-color-b: #d7a66d;
  --node-height: 8px;
  --node-scale-down: 0.5;
  --node-scale-up: 2;
  --node-width: 8px;
}

.loading-wrapper {
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 45px 85px;
  transform: rotate(90deg);
}

.dna-spinner {
  /*   animation: appear 1s linear;
    animation-delay: calc(var(--animation-delay-delta) * 12);
    animation-fill-mode: backwards; */
  height: var(--loader-height);
  position: relative;
  width: var(--loader-width);
}

.text {
  animation: appear 1s linear;
  animation-delay: calc(var(--animation-delay-delta) * 11);
  animation-fill-mode: backwards;
  color: var(--node-color-a);
  font-family: Helvetica;
  /*   font-style: italic; */
  font-weight: 200;
  /*   position: absolute;
    top: 50%;
    left: 50%; */
  /*   transform: translate(-50%, -50%); */
  margin-top: 1rem;
}

.center,
.strand {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 0;
}

.strand {
  width: 100%;
}
.strand.left {
  left: 0;
}
.strand.right {
  align-items: flex-end;
  right: 0;
}

.strand div {
  background: var(--node-color-a);
  border-radius: 50%;
  opacity: 0;
  position: relative;
  height: var(--node-height);
  width: var(--node-width);
}
.strand.left div {
  animation: rotate-right var(--animation-duration) linear infinite;
}
.strand.right div {
  animation: rotate-left var(--animation-duration) linear infinite;
}

.center {
  left: 50%;
  transform: translateX(-50%);
}
.center div {
  animation: rotate-center var(--animation-duration) linear infinite;
  background: var(--node-color-b);
  border-radius: 5px;
  height: var(--bar-height);
  opacity: 0;
  width: var(--bar-width);
}

/* .center div:nth-child(6) {
    visibility: hidden;
  } */

.center div:first-child,
.strand.left div:first-child,
.strand.right div:first-child {
  animation-delay: 0s;
}
.center div:nth-child(2),
.strand.left div:nth-child(2),
.strand.right div:nth-child(2) {
  animation-delay: calc(0s + (var(--animation-delay-delta) * 1));
}
.center div:nth-child(3),
.strand.left div:nth-child(3),
.strand.right div:nth-child(3) {
  animation-delay: calc(0s + (var(--animation-delay-delta) * 2));
}
.center div:nth-child(4),
.strand.left div:nth-child(4),
.strand.right div:nth-child(4) {
  animation-delay: calc(0s + (var(--animation-delay-delta) * 3));
}
.center div:nth-child(5),
.strand.left div:nth-child(5),
.strand.right div:nth-child(5) {
  animation-delay: calc(0s + (var(--animation-delay-delta) * 4));
}
.center div:nth-child(6),
.strand.left div:nth-child(6),
.strand.right div:nth-child(6) {
  animation-delay: calc(0s + (var(--animation-delay-delta) * 5));
}
.center div:nth-child(7),
.strand.left div:nth-child(7),
.strand.right div:nth-child(7) {
  animation-delay: calc(0s + (var(--animation-delay-delta) * 6));
}
.center div:nth-child(8),
.strand.left div:nth-child(8),
.strand.right div:nth-child(8) {
  animation-delay: calc(0s + (var(--animation-delay-delta) * 7));
}
.center div:nth-child(9),
.strand.left div:nth-child(9),
.strand.right div:nth-child(9) {
  animation-delay: calc(0s + (var(--animation-delay-delta) * 8));
}
.center div:nth-child(10),
.strand.left div:nth-child(10),
.strand.right div:nth-child(10) {
  animation-delay: calc(0s + (var(--animation-delay-delta) * 9));
}
.center div:nth-child(11),
.strand.left div:nth-child(11),
.strand.right div:nth-child(11) {
  animation-delay: calc(0s + (var(--animation-delay-delta) * 10));
}

@keyframes rotate-center {
  0% {
    opacity: 0.8;
    transform: rotateY(0deg);
  }
  25% {
    opacity: 0.8;
    transform: rotateY(90deg);
  }
  50% {
    opacity: 0.8;
    transform: rotateY(180deg);
  }
  75% {
    opacity: 0.8;
    transform: rotateY(270deg);
  }
  100% {
    opacity: 0.8;
    transform: rotateY(360deg);
  }
}
@keyframes rotate-left {
  0% {
    background-color: var(--node-color-b);
    filter: brightness(1.5) blur(0.5px);
    opacity: 1;
    transform: translateX(0px) scale(1, 1);
    z-index: 10;
  }
  25% {
    background-color: var(--node-color-b);
    filter: brightness(1) blur(1px);
    opacity: 1;
    transform: translateX(calc(-1 * (var(--loader-width) / 2) + var(--node-width)))
      scale(var(--node-scale-down), var(--node-scale-down));
    z-index: 0;
  }
  50% {
    background-color: var(--node-color-b);
    filter: brightness(1.5) blur(0.5px);
    opacity: 1;
    transform: translateX(calc(-1 * (var(--loader-width)) + var(--node-width))) scale(1, 1);
    z-index: 10;
  }
  75% {
    background-color: var(--node-color-a);
    filter: brightness(1) blur(0px);
    opacity: 1;
    transform: translateX(calc(-1 * (var(--loader-width) / 2) + var(--node-width)))
      scale(var(--node-scale-up), var(--node-scale-up));
    z-index: 10;
  }
  100% {
    background-color: var(--node-color-b);
    filter: brightness(1.5) blur(0.5px);
    opacity: 1;
    transform: translateX(0px) scale(1, 1);
    z-index: 10;
  }
}
@keyframes rotate-right {
  0% {
    background-color: var(--node-color-b);
    filter: brightness(1.5) blur(0.5px);
    opacity: 1;
    transform: translateX(0px);
    z-index: 10;
  }
  25% {
    background-color: var(--node-color-a);
    filter: brightness(1) blur(0px);
    opacity: 1;
    transform: translateX(calc((var(--loader-width) / 2) - var(--node-width)))
      scale(var(--node-scale-up), var(--node-scale-up));
    z-index: 10;
  }
  50% {
    background-color: var(--node-color-b);
    filter: brightness(1.5) blur(0.5px);
    opacity: 1;
    transform: translateX(calc(var(--loader-width) - var(--node-width))) scale(1, 1);
    z-index: 10;
  }
  75% {
    background-color: var(--node-color-b);
    filter: brightness(1) blur(1px);
    opacity: 1;
    transform: translateX(calc((var(--loader-width) / 2) - var(--node-width)))
      scale(var(--node-scale-down), var(--node-scale-down));
    z-index: 0;
  }
  100% {
    background-color: var(--node-color-b);
    filter: brightness(1.5) blur(0.5px);
    opacity: 1;
    transform: translateX(0px) scale(1, 1);
    z-index: 10;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
