.wrapper {
    display: flex;
    width: 100%;
    overflow: scroll;
    min-height: 100vh;
    /* height: 100vh; */
    align-items: stretch;
}

/* ---------------------------------------------------
        CONTENT STYLE
    ----------------------------------------------------- */

#content {
    width: 100%;
    /* padding: 20px; */
    /*min-height: 100vh;*/
    transition: all 0.3s;
}

.main-container {
    padding: 20px;
    overflow-y: auto;
    height: calc(100vh - 130px);
}

/*LOADING*/
.loading-component {
    display: flex;
    /* height: 100vh; */
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.2);
}

.loading-component .spinner-border {
    height: 80px;
    width: 80px;
    border-width: 10px;
}

/* MODAL_LIST */
.add-provider-modal .modal-inputs {
    border-radius: 20px;
}

.add-provider-modal .modal-button {
    background: #2e6e92;
    padding: 14px 30px;
    margin: 0 120px;
    border-radius: 30px;
    color: #fff;
}

.ant-menu-item-only-child {
    padding-left: 64px !important;
}

.change-password-button {
    cursor: pointer;
    background: #2e6e92;
    border-radius: 25px;
    color: #fff;
    padding: 14px 20px;
    border: none;
}

.profile-picture-update {
    width: 60%;
    margin: auto;
}

.profile-picture-upload-button {
    margin-top: 10px;
    background: #2e6e92;
    color: #ffffff;
}

.profile-picture-upload-button:hover {
    transform: translateY(2px);
    color: #fff;
    font-weight: 600;
}

.profile-picture-upload-button:focus {
    box-shadow: none;
}

.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: black;
    background-image: none;
}

.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
    border-color: black;
    background-image: none;
}

button:disabled {
    cursor: default;
    opacity: 0.6;
}
.wrap-text{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
}
.cursor-pointer{
    cursor: pointer;
}
.header-component{
    background-color: #fff;
}
.caretaker-header{
    background-color: #fff;
}
.bottom-sidebar{
    background-color: #fff;
}
.ant-layout-sider-trigger{
    background-color: #f5f5f5;
    color: black;
}
.bg-crimson{
    background-color: #EA047E;
}
/* sidebar sub menu */
.ant-menu-sub{
    width: 108%;
    margin-left: -15px !important;
    font-size: 14px;
}
.react-autosuggest__input{
    width: 100%;
}
.no-internet-toast .react-toast-notifications__toast__dismiss-button {
    display: none;
}
