.container {
    height: 50px;
    width: 100%;
    /*border: 1px solid #bcd1dc;*/
    margin-bottom: 1.5rem;
    border-radius: 5px;
}

.character {
    line-height: 50px;
    font-size: 40px;
    color: white;
    font-weight: 400;
    background-color: rgba(0, 0, 0, 0.4);
    /*border: 1px solid transparent;*/
    border-radius: 8px;
    margin-left: 8px;
}

.character:first-child {
    margin-left: 0;
}

.character--selected {
    border: 1px solid white;
}

.otpContainer{
    justify-content: center;
}

.otpBox{
    width: 40px;
    height: 40px;
    font-size: 36px;
    font-weight: lighter;
    text-align: center;
    border: 2px solid #d9a46e;
    border-radius: 6px;
}