.opt {
  font-size: 25px;
}
.concern-img-div {
  margin-right: 20px;
  position: relative;
}
.concern-img {
  width: 90px;
  object-fit: cover;
  margin-left: 10px;
}
.zoom-img:hover {
  transform: scale(4);
  transition: 0.5s;
}

.concern-remove {
  position: absolute;
  top: 10;
  right: 0;
  cursor: pointer;
}
